.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.loading {
  text-align: center;
  padding-top: 10em;
  padding-bottom: 10em;
  color: #3e8e41;
  font-weight: bolder;
}

.modalimg {}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav li {
  display: inline;
  color: white;
}

.nav {
  right: 2em;
  top: 0em;
  position: absolute;
  display: 'inline-block';
}

.logo {
  width: 12em;
}

.develop {
  position: absolute;
  right: 2em;
  display: inline-block;
}

.slide-container {
  width: 100%;
  height: auto;
}

h3 {
  text-align: center;
}

.each-slide>div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  height: auto;
}

.each-fade .image-container img {
  width: 100%;
  height: 85%;
  z-index: -1;
}

.nav {
  position: static;
  margin-bottom: 8%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.logo {
  z-index: 10;
  position: relative;
}

.modal {
  border-radius: 40px !important;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10em;
}

.side {
  position: fixed;
  top: 60%;
  right: 0;
  z-index: 200;
  opacity: 1;
}

.side span {
  vertical-align: top;
}

.close-button {
  border: none;
  display: block;
  padding: 8px 16px;
  overflow: visible;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap
}

.topright {
  position: absolute;
  left: 0em;
  top: 0em;
}

.product {
  margin-top: 2em !important;
  margin-right: 3em !important;
  margin-left: 5em !important;
  transition: transform .2s;
}

.product:hover {
  opacity: 0.9;
  cursor: pointer;
}

.banner {
  background-color: #ef6c00;
  border-radius: 50px;
  margin-top: 5em;
  max-width: 50%;
  margin: auto;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)
}

.banner h3 {
  color: white;
  padding: 0.5em;
  font-weight: bold;
}

.page {
  display: flex;
  justify-content: center;
  margin: auto;
  border-top: 2px solid #e3f2fd;
  margin-bottom: 0em;
  align-items: center;
}

.pagination {
  display: inline;
  font-weight: bold;
}

.pagination a {
  color: rgb(255, 255, 255);
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border-radius: 50%;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #448aff;
  color: white;
}

.pagination a:hover:not(.active):not(.nostyle) {
  background-color: #ddd;
}

.animated {
  margin-bottom: 60px;
  -webkit-animation-duration: 5s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: rotateInDownLeft;
}

.btn {
  height: fit-content!important
}

.fit {
  width: fit-content !important
}

html {
  scroll-behavior: smooth;
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

.about {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #ff3d00;
  margin-top: 5em;
}

.s {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10em;
}

.testimonial {
  margin-top: 8em;
  background-color: #f44336;
  color: white
}

.sidenav-overlay {
  opacity: 0 !important;
}

.contact {
  display: flex;
  margin-top: 5em;
  margin-left: 5em;
}

.contact img {
  width: 25px;
}

.contact p {
  display: inline-block;
  font-size: 2em;
  font-weight: bold;
  margin-left: 0.5em;
}

.for {
  width: '0' !important
}

/* width */

::-webkit-scrollbar {
  width: 12px;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #004d40;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #88ff00;
  border-radius: 5px;
}

.onlymobile {
  visibility: hidden;
}

@media screen and (max-width: 992px) {
  .onlymobile {
    visibility: visible;
  }
}

@media screen and (max-width: 475px) {
  .phone {
    width: 248px !important;
  }
}

@media screen and (max-width: 680px) {
  .modal {
    width: 100% !important;
  }
}

@media screen and (max-width: 1440px) {
  .product {
    margin-right: 1em !important;
    margin-left: 5em !important;
  }
}

@media screen and (max-width: 1279px) {
  .product {
    margin-right: 2em !important;
    margin-left: 4em !important;
  }
}

@media screen and (max-width: 1107px) {
  .product {
    margin-right: 1em !important;
    margin-left: 4em !important;
  }
}

@media screen and (max-width: 990px) {
  .product {
    margin-right: 0em !important;
    margin-left: 8em !important;
  }
  .row .col.m12 {
    width: 70% !important
  }
}

@media screen and (max-width: 714px) {
  .product {
    margin-right: 0em !important;
    margin-left: 6em !important;
  }
  .row .col.m12 {
    width: 70% !important
  }
}

@media screen and (max-width: 542px) {
  .product {
    margin-right: 0em !important;
    margin-left: 5em !important;
  }
  .row .col.m12 {
    width: 70% !important
  }
}

@media screen and (max-width: 480px) {
  .product {
    margin-right: 0em !important;
    margin-left: 4em !important;
  }
  .row .col.m12 {
    width: 70% !important
  }
}

@media screen and (max-width: 600px) {
  .banner {
    max-width: 90%;
  }
}

@media screen and (max-width: 475px) {
  .phone1 {
    width: 248px !important;
    font-size: 10px;
  }
}

@media screen and (max-width: 330px) {
  .contact p {
    font-size: 1.2em;
    margin-left: 1em;
  }
}

@media only screen and (max-width: 482px) {
  .logo {
    width: 8em;
    vertical-align: middle;
    position: relative;
    z-index: 10;
  }
}

@media only screen and (max-width: 374px) {
  .logo {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
  }
}

@media only screen and (max-width: 374px) {
  .logo {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
  }
}

@media only screen and (max-width: 631px) {
  .develop {
    position: static;
    display: block;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}